import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';

// MSAL configuration
const configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY ?? '',
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const authRequest = {
  scopes: ['openid'],
};

export const clientApplication = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <MsalProvider instance={clientApplication}>
    
        <App />
     
    </MsalProvider>
  </React.StrictMode>
);
