const size = {
  xs: '375px',
  s: '480px',
  sm: '768px',
  lg: '1200px',
  xl: '1201px',
};
const device = {
  xs: `(max-width: ${size.xs})`,
  s: `(max-width: ${size.s})`,
  sm: `(max-width: ${size.sm})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
};

const breakPoints = {
  size,
  device,
};
export default breakPoints;
