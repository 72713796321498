import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';

import SpecificBadge from './SpecificBadge';

const AnimatedRoutesSpecific = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)}>
      <Routes location={location} key={location.pathname}>
        <Route path='/specificbadge/:id' element={<SpecificBadge />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutesSpecific;
