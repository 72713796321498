import { BrowserRouter as Router } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import CreateBadge from './components/CreateBadge';
// import UserView from './components/Userview';
// import LandingPage from './components/LandingPage';
//import Nav from './components/Nav/Nav';
// import OverallView from './components/OverallView';
// import SpecificBadge from './components/SpecificBadge';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import AnimatedRoutes from './components/AnimatedRoutes';
import AnimatedRoutesSpecific from './components/AnimatedRoutesSpecific';
import { SignInButton } from './components/SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';
import Nav from './components/Nav/Nav';

function App() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>

       
   
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
         {isAuthenticated ? <Nav/>: <SignInButton />}
          <AuthenticatedTemplate>
            <AnimatedRoutes />
          </AuthenticatedTemplate>

          <AnimatedRoutesSpecific />
        </Router>
      </LocalizationProvider>
    </>
  );
}

export default App;
