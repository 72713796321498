import { useState } from 'react';
import styled from 'styled-components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '2rem',
      }}
    >
      <TextField
        sx={{ width: 250 }}
        InputProps={{ sx: { height: 30 } }}
        focused
        color='secondary'
        type='text'
        value={copyText}
        readOnly
      />
      {/* Bind our handler function to the onClick button property */}
      <IconButton color='secondary' onClick={handleCopyClick}>
        <span>
          {isCopied ? (
            'Copied!'
          ) : (
            <ContentCopyIcon
              sx={{
                fontSize: '1.6rem',
              }}
            />
          )}
        </span>
      </IconButton>
    </div>
  );
}

export default ClipboardCopy;
